import API from "../api.service";

export default {
  get PATH() {
    return "post-public";
  },

  async getAllPosts({
    territorial_jurisdiction,
    categories
  }) {
    const params = new URLSearchParams();
    params.append("territorial_jurisdiction", territorial_jurisdiction);

    categories.forEach(el => {
      params.append("categories", el);
    });

    const r = await API.get(`${this.PATH}/`, {
      params
    });
    return r.data;
  },

  async getAll({
    territorial_jurisdiction,
    categories
  }) {
    let params = {
      country: territorial_jurisdiction,
    };
    if (categories) {
      params.categories = categories
    }
    const r = await API.get(`${this.PATH}/get_reports/`, {
      params
    });
    return r.data;
  },

  async getOne(url) {
    const r = await API.get(url);

    return r.data;
  },

  async filterReports({
    country,
    filterCountry,
    categories,
    sourceCountry
  }) {
    const params = new URLSearchParams();
    if (filterCountry) {
      params.append("source__territorial_jurisdiction", filterCountry);
    }
    params.append("territorial_jurisdiction", country);
    categories.forEach(el => {
      params.append("categories", el);
    });

    if (sourceCountry) {
      params.append("source_country", sourceCountry);
    }

    const r = await API.get(`${this.PATH}/`, {
      params,
    });

    return r.data;
  },

  async download({
    country,
    categories
  }) {
    const params = new URLSearchParams();
    params.append("country", country);
    categories.forEach(el => {
      params.append("category", el);
    });
    return await API.get(`${this.PATH}/get_report_pdf/`, {
      params,
      responseType: "blob",
    });
  },
};