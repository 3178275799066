<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <!-- Langing Page -->
    <div v-if="!territorial_jurisdiction" class="row">
      <div class="col-12">
        <div class="card card-custom py-20 px-20">
          <div class="card-body">
            <p class="text-center font-size-h1 font-weight-bolder pb-5">
              {{ $t("You can see the reports of one of these countries or many others") }}
            </p>
            <p class="text-center mb-20">
              <country-flag v-for="(flag, i) in landingFlags" :key="i" :country-iso="flag" big
                class="mx-4"></country-flag>
            </p>
            <p class="text-center font-size-h2 font-weight-bold">
              {{ $t("Select from the report's country from the list below") }}
            </p>
            <div class="d-flex justify-content-center mb-20">
              <euro-select v-model="territorial_jurisdiction" class-name="mb-0 p-0" style="width:400px;"
                :placeholder="$t('Country')" :options="countriesSelect" searchable></euro-select>
            </div>
            <!--Sezione legenda-->
            <p class="text-center mb-5 font-size-h3">
              {{ $t("The right colored box in the report indicate the priority of the post:") }}
            </p>
            <p class="text-center">
              <span v-for="(rl, i) in riskLevelLegend" :key="i"
                class="label label-inline mr-2 font-weight-bolder label-xl" :class="rl.labelClass">{{ rl.label }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- My Reports Page -->
    <div v-if="territorial_jurisdiction" class="row">
      <!-- Top bar -->
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-body d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-2 py-2">
              <country-flag v-if="territorial_jurisdiction" class="mr-4"
                :country-iso="territorial_jurisdiction"></country-flag>
              <h3 class="font-weight-bold mb-0 mr-10">{{ $t('Reports') }}</h3>
            </div>
            <div class="d-flex align-items-center">
              <div class="mr-4">{{ $t('Choose country') }}</div>
              <euro-select v-model="territorial_jurisdiction" class-name="mr-4 mb-0 p-0" style="width:400px"
                :placeholder="$t('Country')" :options="countriesSelect" searchable></euro-select>
              <!--<b-button v-if="territorial_jurisdiction" v-b-tooltip.hover variant="light-primary"
                class="font-weight-bolder" :disabled="isDownloading ? true : false"
                :title="$t('Download all reports for this country')"
                :class="{ 'spinner spinner-light spinner-right': isDownloading }"
                @click="downloadPdf([], [territorial_jurisdiction])">
                <i class="fas fa-file-pdf"></i>
                {{ $t("Download All") }}
              </b-button>-->
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card card-custom">
          <div class="card-body pr-0">
            <div class="d-flex">
              <!-- Categories Tree -->
              <div>
                <h3 class="align-items-start flex-column">
                  <div class="card-label font-weight-bold text-dark">{{ $t("Report index") }}</div>
                  <span class="text-muted mt-3 font-weight-bold font-size-sm">{{ $t("Choose one category from the list")
                    }}</span>
                </h3>
                <div v-if="isLoadingCategories" class="spinner"></div>
                <v-jstree v-if="items.length" :data="items" size="small" whole-row @item-click="itemClick">
                  <template slot-scope="_">
                    <div v-if="_.model.file == 'file' || _.model.file == 'map'" class="font-size-xs brk-cont">
                      <i class="text-primary mdi"
                        :class="{ 'mdi-file': _.model.file == 'file', 'mdi-map': _.model.file == 'map' }"
                        style="font-size: 15px" role="presentation"></i>
                      <span class="brk">{{ _.model.element.name }}</span>
                      <!-- <span v-b-tooltip.hover
                        :title="`${$t('Expiring')}: ${DateService.format(_.model.element.expiring_date, date_format)}`"
                        class="label label-warning label-xl label-dot text-capitalize ml-4 font-size-xs">
                      </span> -->
                    </div>
                    <div v-else class="font-size-xs brk-cont">
                      <i class="mdi mdi-file-lock text-danger" style="font-size: 15px" role="presentation"></i>
                      <span class="brk">{{ _.model.element.name }}</span>
                    </div>
                  </template>
                </v-jstree>
              </div>

              <!-- Report list -->
              <div ref="reportList" class="col-xxx w-100">
                <h2 v-if="categorySelected" class="font-weight-bolder mr-7 mb-7 text-right">
                  {{ categorySelected.name }}
                </h2>
                <!--Card fonte e download-->
                <div v-if="categorySelected && !showInfoCard && !showChat && !isPurchasable && showReport"
                  class="card card-custom box-none mb-3 w-100">
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="mr-4">{{ $t("Select autority report") }}</div>
                      <euro-select v-model="reportsCountryFilter" class-name="mb-0 mr-2" style="width: 200px;"
                        :placeholder="$t('Source Country')" :multiselect-props="countriesSelectProps"
                        @input="getReports"></euro-select>
                      <!-- <b-button v-if="territorial_jurisdiction && actualReport.length" variant="light-primary"
                        class="font-weight-bolder" :disabled="isDownloading ? true : false"
                        :class="{ 'spinner spinner-light spinner-right': isDownloading }"
                        @click="downloadPdf([categorySelected], [territorial_jurisdiction])">
                        <i class="fas fa-file-pdf"></i>
                        {{ $t("Download") }}
                      </b-button> -->
                    </div>
                  </div>
                </div>

                <div v-if="showIntro" class="card card-custom box-none w-100">
                  <div class="card-body px-15 py-20">
                    <!--Sezione bandiera + titolo-->
                    <div class="text-center pb-6">
                      <country-flag :country-iso="territorial_jurisdiction" big></country-flag>
                    </div>
                    <h3 class="text-center">{{ countriesMap[territorial_jurisdiction].name }}</h3>
                    <!-- bottoni per acquisto -->
                    <div class="text-center">
                      <div class="text-center font-size-h4 font-weight-bold mt-8 mb-4">
                        {{ $t("In this section you can find reports for each country divided by categories,") }}
                        {{ $t("select a category to start consulting the report or ask a question to the AI Assistant")
                        }}
                      </div>
                    </div>
                  </div>
                </div>

                <!--Landing Card-->
                <div v-if="showInfoCard" class="card card-custom box-none w-100">
                  <div class="card-body px-15 py-20">
                    <!--Sezione bandiera + titolo-->
                    <div class="text-center pb-6">
                      <country-flag :country-iso="territorial_jurisdiction" big></country-flag>
                    </div>
                    <!-- bottoni per acquisto -->
                    <div class="text-center">
                      <div class="text-center font-size-h4 font-weight-bold mt-8 mb-4">
                        {{ $t("You don't have the subcription for the selected category") }}
                      </div>
                      <div v-if="showReport">
                        <!-- Bottoni report -->
                        <button v-if="isReportInCart" class="btn btn-danger font-weight-bolder text-uppercase"
                          @click="removeItem(categorySelected.id + territorial_jurisdiction)">
                          <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                          </span>
                          {{ $t("Remove from cart") }}
                        </button>
                        <span v-else-if="isReportPending" class="bg-warning rounded p-2">
                          {{ $t("The order is pending approval") }}
                        </span>
                        <button v-else-if="isReportParentInCart"
                          class="btn btn-success font-weight-bolder text-uppercase" disabled>
                          <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                          </span>
                          {{ $t("Subscribe here") }}
                        </button>
                        <button v-else class="btn btn-success font-weight-bolder text-uppercase"
                          @click="addToCartReport">
                          <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                          </span>
                          {{ $t("Subscribe here") }}
                        </button>
                      </div>
                      <div v-else>
                        <!-- Bottoni mappe -->
                        <button v-if="isMapInCart" class="btn btn-danger font-weight-bolder text-uppercase"
                          @click="removeItem(item.risk_maps.id)">
                          <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/General/Trash.svg" />
                          </span>
                          {{ $t("Remove from cart") }}
                        </button>
                        <span v-else-if="isMapPending" class="bg-warning rounded p-2">
                          {{ $t("The order is pending approval") }}
                        </span>
                        <button v-else class="btn btn-success font-weight-bolder text-uppercase"
                          :disabled="isButtonAddMapDisabled" @click="addToCartMap">
                          <span class="svg-icon svg-icon-md">
                            <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
                          </span>
                          {{ $t("Subscribe here") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="showChat" class="card card-custom box-none w-100">
                  <div class="card-body px-15 py-20">
                    <!--Sezione bandiera + titolo-->
                    <div class="text-center pb-6">
                      <country-flag :country-iso="territorial_jurisdiction" big></country-flag>
                    </div>
                    <div class="text-center mt-6">
                      <div class="text-center font-size-h4 font-weight-bold mt-8 mb-4">
                        {{ $t("In this section you can find reports for each country divided by categories,") }}
                        {{ $t("select a category to consult the report or ask a question to the AI Assistant") }}
                      </div>
                      <b-button class="font-weight-bolder" @click="showChatGPT = true">
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="/media/svg/icons/openai.svg" />
                        </span>
                        {{ $t("Ask a question to the AI assistant") }}</b-button>
                    </div>
                    <div class="mt-6">
                      <ReportChat v-if="showChatGPT" :country-selected="territorial_jurisdiction"
                        :title="categorySelected" @close="showChatGPT = false" @reload="reloadReportChat"></ReportChat>
                    </div>
                  </div>
                </div>

                <div v-if="!showInfoCard && !showChat && !isPurchasable">
                  <!-- Lista reports -->
                  <div v-if="showReport">
                    <div v-if="isLoadingPosts">
                      <div class="text-center my-20">
                        <h4>{{ $t("Loading posts...") }}</h4>
                      </div>
                    </div>

                    <div v-for="( report, idx ) in  actualReport" :key="idx"
                      class="card card-custom box-none mb-3 w-100">
                      <div class="card-header ribbon ribbon-clip ribbon-right">
                        <div class="ribbon-target" style="top: 15px; height: 45px;">
                          <span class="ribbon-inner"
                            :class="riskLevelLegend[report.stoplight - 1]?.colorClass"></span>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="card-title align-items-start flex-column py-3 w-100">
                          <div class="d-flex align-items-center justify-content-between w-100">
                            <div class="d-flex align-items-center">
                              <div v-if="report.ai_post" style="width: 25px" class="mr-4">
                                <inline-svg src="/media/svg/icons/chatgpt.svg" />
                              </div>
                              <h3 v-if="!report.ai_post" class="card-label font-weight-bolder">
                                {{ report.title }}
                              </h3>
                              <h3 v-else class="card-label font-weight-bolder">
                                {{ report.categories[0].name }}
                              </h3>
                            </div>
                            <div>
                              <b-dropdown v-b-tooltip.hover="$t('Show source detail to specific country')" no-caret
                                right no-flip :text="$t('Sources')" toggle-class="font-weight-bold">
                                <div class="navi navi-hover">
                                  <b-dropdown-item v-for="(source, i) in report.sources_body" :key="i" tag="div"
                                    class="navi-item" @click="modalReport(source, report.title)">
                                    {{ source.country }}
                                  </b-dropdown-item>
                                </div>
                              </b-dropdown>
                            </div>
                          </div>
                          <div class="mt-1">
                            <b-badge class="align-self-start" style="font-size:11px"
                              :variant="!report.valid_to || dateDiff(report.valid_to) ? 'success' : 'danger'">
                              <span>{{ $t("Valid From") }}: {{ DateService.format(report.valid_from,
      date_format) }}</span>
                              <span v-if="report.valid_to">{{ $t("To") }}: {{
      DateService.format(report.valid_to, date_format) }}</span>
                            </b-badge>
                          </div>
                        </div>
                      </div>
                      <div class="card-body nop">
                        <div class="report-body" v-html="report.body"></div>
                      </div>
                    </div>
                  </div>

                  <!-- Risk map -->
                  <div v-else class="col-xx w-100">
                    <div v-if="riskMapEnable" class="card card-custom box-none">
                      <div class="card-body">
                        <div class="d-flex align-items-center justify-content-between mb-8">
                          <div class="d-flex align-items-center mr-2 py-2">
                            <country-flag v-if="territorial_jurisdiction"
                              :country-iso="territorial_jurisdiction"></country-flag>
                          </div>
                          <div class="d-flex align-items-center">
                            <ul class="level-legend my-0">
                              <li v-for="( levelLegend, idx ) in  riskLevelLegendMap " :key="idx"
                                class="d-flex align-items-center">
                                <rectangle :label="levelLegend.label" :color="levelLegend.color"></rectangle>
                                <span class="mr-2 font-weight-bold">{{ levelLegend.text }}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <google-maps-map height="600px" class="border" :geocoder-options="gmapGeocoder"
                          :kml-layers-urls="kmlUrl"></google-maps-map>
                        <div class="pre-assessment">
                          <h2>{{ $t("Country Assessment") }}</h2>
                          <div v-if="showPerAssessment" class="pre-content">
                            <span v-html="convertMarkdownToHtml(contentPerAssessment)"></span>
                            <h2>{{ $t("Vaccinations & Risk Categories") }}</h2>
                            <div class="row">
                              <div v-if="mandatoryVaccinations.length || recommendedVaccinations.length" class="col">
                                <div v-if="mandatoryVaccinations.length" class="mb-6">
                                  <h3>{{ $t("Mandatory Vaccinations:") }}</h3>
                                  <div v-for="mandatoryVaccination in mandatoryVaccinations"
                                    :key="mandatoryVaccination">
                                    <div class="d-flex align-items-center">
                                      <span class="mb-2 badge badge-warning">{{ mandatoryVaccination }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="recommendedVaccinations.length">
                                  <h3>{{ recommendedVaccinations.length }} {{ $t("Recommended Vaccinations:") }}</h3>
                                  <div v-for="recommendedVaccination in recommendedVaccinations"
                                    :key="recommendedVaccination">
                                    <div class="d-flex align-items-center">
                                      <span class="mb-2 badge badge-info">{{ recommendedVaccination }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="categoriesOfRisks.length" class="col">
                                <h3>{{ $t("Risk Categories:") }}</h3>
                                <div v-for="categorieRisk in categoriesOfRisks" :key="categorieRisk.id">
                                  <div class="d-flex align-items-center">
                                    <span class="mb-2 badge badge-secondary">{{ categorieRisk.name }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else>
                            <span class="pr-4">{{ $t("Request pre-assessment of the country") }}</span>
                            <button class="btn btn-success font-weight-bolder text-uppercase"
                              @click="requestPerAssessment">
                              <span class="svg-icon svg-icon-md">
                                <inline-svg src="/media/svg/icons/General/Binocular.svg" />
                              </span>
                              {{ $t("Request Pre Assessment") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="report-modal" size="xl" hide-footer>
      <template #modal-header>
        <div class="d-flex justify-content-between align-items-center py-0 w-100">
          <div>
            <h3 class="font-weight-bolder">{{ showReportSource.country }} {{ $t("Source") }}</h3>
            <h5 class="my-0 font-weight-bold">
              <span class="badge align-self-start badge-success">{{ showReportSource.title }}</span>
            </h5>
          </div>
          <div class="d-flex align-items-start">
            <b-button @click="closeModalReport">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <div v-html="showReportSource.body"></div>
    </b-modal>
  </div>
</template>

<script>
import CountryFlag from "@/view/components/CountryFlag.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import PostCategoryService from "@/core/services/post/post-category.service";
import ReportsService from "@/core/services/risk/reports.service";
import DateService from "@/core/services/date.service";
import RiskService from "@/core/services/risk/risk.service";
import { mapState, mapGetters, mapActions } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import moment from "moment";
import Swal from "sweetalert2";
import VJstree from 'vue-jstree'
import eventBus from "@/core/services/event/eventBus.js"
import ReportChat from "@/view/components/chat/ReportChat.vue"
import i18n from "@/core/plugins/vue-i18n";
import MarkdownIt from "markdown-it";

function findFirstNodeParent(node) {
  if (node.model.element.parent_id == null) {
    return node.model.element
  } else {
    return findFirstNodeParent(node.$parent)
  }
}

export default {
  components: {
    CountryFlag,
    Rectangle,
    GoogleMapsMap,
    VJstree,
    ReportChat
  },

  data() {
    return {
      moment,
      DateService,
      landingFlags: ["IT", "FR", "TN", "NL", "TR"],
      kmlUrl: [],

      // Download PDF
      isDownloading: false,

      // Filtrata country
      territorial_jurisdiction: null,
      reportsCountryFilter: null,
      availables: ["AU", "FR", "JP", "NL", "GB", "US", "IT", "DE"],
      categoriesRaw: [],
      capabilities: [],
      showTutorial: false,

      // Flags
      riskLevelLegend: [
        { code: 1, label: "Low", colorClass: "bg-success", labelClass: "label-success" },
        { code: 2, label: "Medium", colorClass: "bg-warning", labelClass: "label-warning" },
        { code: 3, label: "High", colorClass: "bg-danger", labelClass: "label-danger" },
      ],
      riskLevelLegendMap: [
        { code: 1, label: "A", text: this.$t("Low"), color: "#16a085" },
        { code: 2, label: "B", text: this.$t("Moderate"), color: "#f1c40f" },
        { code: 3, label: "C", text: this.$t("Medium"), color: "#f39c12" },
        { code: 4, label: "D", text: this.$t("High"), color: "#c0392b" },
      ],
      isLoadingPosts: false,
      isLoadingCategories: false,
      riskMapEnable: false,
      showReport: true,

      // API data
      actualReport: [],

      // Treeview
      active: null,
      items: [],
      showReportSource: false,
      reportFirstCategory: null,
      showInfoCard: false,
      showChat: false,
      showIntro: true,
      categorySelected: null,
      showChatGPT: false,
      showPerAssessment: false,
      contentPerAssessment: '',
      recommendedVaccinations: '',
      mandatoryVaccinations: '',
      categoriesOfRisks: '',
      md: new MarkdownIt(),
    }
  },

  computed: {
    ...mapState("user", ["countryOfEstablishment"]),
    ...mapState("constants", ["countries"]),
    ...mapState("capabilities", ["risk_map", "pending"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapGetters("user", ["date_format"]),
    ...mapGetters("capabilities", ["getCapabilitiesForCountry", "getActiveCapabilities", "getPendingCapabilities"]),
    ...mapGetters("cart", ["getItems"]),

    countriesSelect() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },
    countriesNames() {
      let obj = {};
      this.countries.map((el) => (obj[el.iso] = this.$t(el.name)));
      return obj;
    },
    countriesSelectProps() {
      return {
        options: this.countriesComputed,
        multiple: false,
        "allow-empty": true,
        searchable: true,
      };
    },
    countriesComputed() {
      const selectable = [];
      this.availables.forEach((el) => {
        if (this.countriesMap[el]) {
          selectable.push({
            text: this.countriesMap[el].name,
            value: this.countriesMap[el].iso,
          });
        }
      });
      return selectable;
    },
    queryIsoCountry() {
      return this.$route.query.iso ?? false
    },
    gmapGeocoder() {
      return {
        componentRestrictions: {
          country: this.territorial_jurisdiction,
        },
      };
    },
    isReportPending() {// controllo report in pending
      const item = this.categorySelected;

      let pendingCountry = this.pending.find(order => this.territorial_jurisdiction == order.country);
      return pendingCountry ? pendingCountry.categories.some(category => category.id == item.id || category.id == item.parent_id) : false;
    },
    isReportInCart() {
      const item = this.categorySelected;// controllo report nel carrello
      let items = this.getItems;

      return item ? items.some(el => el.id == item.id + this.territorial_jurisdiction) : false;
    },
    isMapPending() {// Controllo mappa in pending
      return this.pending.find(order => order.risk_map && this.territorial_jurisdiction == order.country)
    },
    isMapInCart() {// Controllo mappa nel carrello
      let items = this.getItems;

      return items.some(el => el.id == (this.active) + this.territorial_jurisdiction);
    },
    isButtonAddMapDisabled() {
      return this.isMapPending || this.isMapInCart
    },
    isReportParentInCart() {
      if (!this.categorySelected) {
        return false
      }
      const item = this.categorySelected; // controllo report nel carrello
      let items = this.getItems;
      return items.length ? items.some(el => el.id == item.parent_id + this.territorial_jurisdiction) : false;
    },
    isPurchasable() {
      if (!this.reportFirstCategory) {
        return false
      }
      const capabilities = this.getCapabilitiesForCountry(this.territorial_jurisdiction)
      if (capabilities) {
        const categoryIds = capabilities.categories.map(el => el.id)
        return !categoryIds.includes(this.reportFirstCategory.id)
      }
      return true
    }
  },

  watch: {
    async territorial_jurisdiction(value) {
      // checkRiskMap check if map risk is enabled
      await this.checkRiskMap()
      await this.getUserCategories(value)
      // Get user capabilities
      this.reportsCountryFilter = null
      this.getMapItems(value)
      // Reload Reports
      await this.getReports()
    },
    active: {
      async handler(value) {
        // show risk map
        this.showReport = value !== 117

        if (this.isPurchasable) {
          this.showInfoCard = true
          this.showChat = false
          return
        }

        if (this.showReport) {
          if (this.categorySelected.id != this.reportFirstCategory.id) {
            this.showInfoCard = false
            this.showChat = false
            this.reportsCountryFilter = null;
            await this.getReports();
          } else {
            this.showInfoCard = false
            this.showChat = true
          }
        } else {
          this.showInfoCard = false
          this.showChat = false
          await this.checkRiskMap()
        }
      },
      deep: true
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Reports") }]);
    if (this.territorial_jurisdiction) {
      await this.checkRiskMap()
      await this.getUserCategories(this.territorial_jurisdiction)
    }
    this.anchorHashCheck()
  },

  beforeMount() {
    if (this.queryIsoCountry) {
      this.territorial_jurisdiction = this.queryIsoCountry
    }
  },

  methods: {
    ...mapActions("cart", ["addItem", "addParentItem", "removeItem"]),
    async downloadPdf(categories, territorial_jurisdiction) {
      this.isDownloading = true;
      const categoriesArr = categories.map((el) => el.id);
      await ReportsService.download({ country: territorial_jurisdiction[0], categories: categoriesArr }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers['content-disposition'];
        let fileName = `${new Date().toLocaleDateString()}.pdf`;
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename=(.+)/);
          if (fileNameMatch.length === 2)
            fileName = fileNameMatch[1];
        }
        link.setAttribute('download', fileName);
        link.click();
        link.remove();
      }).catch(err => {
        backendErrorSwal(err?.response?.data?.detail ?? this.$t("Unable to download the report, please try again later"));
        console.log(err);
      }).finally(() => {
        this.isDownloading = false;
      });
    },
    dateDiff(to) {
      const diff = moment(to).diff(moment());
      return diff;
    },
    mapItem(element, capabilities, icon = null) {
      let fileIcon = icon
      if (!fileIcon) {
        fileIcon = capabilities.find(cat => cat.id == element.id) ? 'file' : 'lock'

        // RISK MAP ID 117
        if (fileIcon != 'lock' && element.id == 117) {
          fileIcon = 'map'
        }
      }
      const item = {
        children: [],
        file: fileIcon,
        opened: false,
        // pending: false,
        element
      }

      // TODO: da definire
      // if (pending) {
      //   childItem.pending = pending.categories.some((cat) => cat.id === item.id || cat.id == item.parent_id);
      // }

      if (element.children) {
        item.children = element.children.map(child => this.mapItem(child, capabilities, fileIcon))
      }

      return item
    },
    getMapItems(country) {
      this.capabilities = this.getCapabilitiesForCountry(country)
      // let pendingForCountry = this.pending.find(el => el.country == (this.territorial_jurisdiction));
      let categories = []
      if (this.capabilities) {
        categories = Object.assign([], this.capabilities.categories)
      }

      this.items = this.categoriesRaw.map(row => this.mapItem(row, categories))
    },
    async getUserCategories(country) {
      this.isLoadingCategories = true;

      let res = await PostCategoryService.getAll({ tree_view: true })
      this.categoriesRaw = res;
      this.getMapItems(country)

      this.isLoadingCategories = false;
    },
    async checkRiskMap() {
      this.riskMapEnable = this.risk_map.find(risk => risk == this.territorial_jurisdiction)
      if (this.riskMapEnable) {
        await this.getKmlLayerUrl()
        await this.getPerAssessment()
      }
    },
    async getReports() {
      this.actualReport = []
      this.showTutorial = false

      if (!this.territorial_jurisdiction || !this.active) {
        return
      }

      if (!this.isPurchasable) {
        this.isLoadingPosts = true;

        let params = {
          country: this.territorial_jurisdiction,
          // filterCountry: this.reportsCountryFilter,
          categories: [this.active],
          sourceCountry: this.reportsCountryFilter
        };
        let reports = await ReportsService.filterReports(params);

        this.availables = reports.source_countries

        this.actualReport = reports.results.map(res => {
          const sourcesBody = []
          if (res.ai_post_from.length) {
            res.ai_post_from.forEach(element => {
              sourcesBody.push({
                country: this.countriesMap[element.source.territorial_jurisdiction[0]].name,
                body: element.body,
              })
            });
          } else {
            //TODO: aggiungere fonte generale
          }
          res.body = res.body.replace(
            /<span class='flag-([A-Z]{2})' data-country='[A-Z]{2}' title='[A-Z]{2}'>.*?<\/span>/gi,
            (match, countryCode) => `<img src="/media/flags/${countryCode.toLowerCase()}.svg" alt="Country Flag ${countryCode}" class="flag flag-${countryCode}" title="${this.countriesMap[countryCode].name}" >`
          );
          return { ...res, sources_body: sourcesBody, sources_selected: "" }
        });

        this.isLoadingPosts = false;
      } else {
        this.showTutorial = true
      }
    },
    async getKmlLayerUrl() {
      this.kmlUrl = [];
      try {
        const { kml_url: url } = await RiskService.getCountryRiskMap(this.territorial_jurisdiction);
        this.kmlUrl = [url];
      } catch (err) {
        console.log('@error getKmlLayerUrl report', err)
      }
    },
    scrollToTop() {
      eventBus.$emit('open-mini-cart')
    },
    addToCartMap() {
      const item = this.countries.find(el => el.iso == this.territorial_jurisdiction)
      const newItem = {
        name: 'Risk Map',
        slug: 'risk-map',
        type: 'map',
        price: item.risk_maps.user_price.monthly, // User_price ha tutti i prezzi su duration
        countries: [item.iso],
        id: this.active + this.territorial_jurisdiction
      }
      this.addItem(newItem) // true è per salvere il carrello nel DB
      this.scrollToTop()
    },
    addToCartReport() {
      const item = this.reportFirstCategory;
      let isParentWithChildren = false;
      let parentItem = {};

      if (!item.parent_id)// è un parent
      {
        parentItem = this.items.find(el => el.id == item.id)
        if (parentItem?.children?.length) {
          let info = "<ul>";
          parentItem.children.forEach(el => {
            info += `<li>- ${el.text}</li>`;
          })
          info += '</ul>'
          Swal.fire({
            icon: "info",
            title: "In questo modo aggiungerai anche le sottocategorie nel carrello:",
            html: '<div style="text-align:left">' + info + '</div>',
          })
          isParentWithChildren = true;
        }
      }
      const newItem = {
        name: item.name,
        slug: item.slug,
        type: 'report',
        price: item.report_price,
        countries: [this.territorial_jurisdiction],
        categories: [item.id],
        id: item.id + this.territorial_jurisdiction
      }
      if (isParentWithChildren)
        this.addParentItem({ item: newItem, children: parentItem.children });
      else
        this.addItem(newItem)

      this.scrollToTop()
    },
    async itemClick(node) {
      node.model.opened = true

      this.showIntro = false
      if (this.active == node.model.element.id) {
        return
      }

      this.reportFirstCategory = null
      this.showInfoCard = false
      this.active = node.model.element.id;
      this.categorySelected = node.model.element;
      this.reportFirstCategory = findFirstNodeParent(node)
    },
    anchorHashCheck() {
      if (window.location.hash === this.$route.hash) {
        this.$refs["reportList"]?.scrollIntoView({ behavior: "smooth" })
      }
    },
    modalReport(source, title) {
      this.showReportSource = source
      this.showReportSource['title'] = title
      this.$bvModal.show('report-modal')
    },
    closeModalReport() {
      this.$bvModal.hide('report-modal')
    },
    reloadReportChat(isWsOpen) {
      if (isWsOpen) {
        // If WebSocket connection is open, reload the chat
        this.showChatGPT = false;
        this.$nextTick(() => {
          this.showChatGPT = true;
        });
      } else {
        // If WebSocket connection is closed, show an error message
        backendErrorSwal(null, i18n.t('Assistant WebSocket connection failed. Please try to reload again or try later.'));
      }
    },
    convertMarkdownToHtml(markdownText) {
      return this.md.render(markdownText);
    },
    async getPerAssessment() {
      this.contentPerAssessment = ''
      this.mandatoryVaccinations = ''
      this.recommendedVaccinations = ''
      this.categoriesOfRisks = ''

      this.showPerAssessment = false

      await RiskService.getCountryPerAssessment(this.territorial_jurisdiction).then((r) => {
        if (r.status == 'ok' && r.data.assessment_json && r.data.assessment_json.hasOwnProperty('pre_assessment')) {
          this.contentPerAssessment = r.data.assessment_json.pre_assessment
          this.recommendedVaccinations = r.data.assessment_json.recommended_vaccinations
          this.mandatoryVaccinations = r.data.assessment_json.mandatory_vaccinations
          this.categoriesOfRisks = r.data.assessment_json.categories_of_risks
          this.showPerAssessment = true
        }
      }).catch((err) => {
        console.log(err)
        backendErrorSwal(null, i18n.t('There was an error, contact technical support'));
      });
    },
    async requestPerAssessment() {
      await RiskService.requestCountryPerAssessment(this.countryIso).then(() => {
        Swal.fire({
          icon: "info",
          title: this.$t("Your request has been sent to the risk assessment team, you will receive a notification as soon as it is ready"),
        });
      }).catch(() => {
        backendErrorSwal(null, i18n.t('There was an error, contact technical support'));
      });
    }
  }
}
</script>

<style>
.text-light-primary-bis {
  color: #a3bcff !important;
}

.card-body .report-body ul {
  padding: 0 1rem;
}

.v-treeview-node__label {
  cursor: pointer;
}

ul.level-legend {
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.card-body.nop {
  padding: 0 !important;
}

.box-none {
  background-color: #fbfbfb;
}

.box-none .card-header {
  border-top: 1px solid #EBEDF3;
  border-left: 1px solid #EBEDF3;
  background-color: #efefef !important;
}

.report-body {
  padding-top: 1rem;
  border-left: 1px solid #EBEDF3;
}

.report-body h2,
.report-body h3,
.report-body h4,
.report-body p {
  padding: 0.25rem 2rem;
  margin: 0;
}

.report-body h2,
.pre-assessment h2 {
  border-bottom: 1px solid #EBEDF3;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.pre-assessment h2 {
  margin-top: 2rem;
}

img.flag {
  min-height: 18px;
  width: 24px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, .5);
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

img.flag.flag-IT {
  box-shadow: 0px 0px 3px 0px rgb(0 170 189);
}

.pre-content ul {
  padding-left: 2rem;
}
</style>